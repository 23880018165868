import { Box, Center, HStack, Text, VStack } from "native-base";
import React, { useContext } from "react";
import { View, Button } from "react-native";
import AuthContext, { AuthStatus } from "../auth/auth_context";
import { RootStackScreenProps, RootStackRouteProps } from "../navigation/types";

interface IHomeScreenProps {
  extraData: string;
  navigation: RootStackScreenProps<"Home">;
  route: RootStackRouteProps<"Home">;
}

export default function HomeScreen(props: IHomeScreenProps) {
  const auth = useContext(AuthContext);

  const onLoginPressed = async () => {
    let user = auth[0].firebase_user;
    if (auth[0].status !== AuthStatus.LOGGED_IN) {
      if (auth[1].signInWithGoogle === undefined) {
        return;
      }
      const credentials = await auth[1].signInWithGoogle();
      user = credentials?.user;
    }
    if (user?.uid) {
      props.navigation.push("Profile", { userId: user?.uid });
    }
  };

  return (
    <View>
      <Center py="5" px={["5", "10", "20", "20"]}>
        <Box top="0" w="100%">
          <HStack justifyContent="right" space="5">
            <Text>About</Text>
            <Text>Contact</Text>
            <Text>Careers</Text>
            <Text>Join the Wait-list</Text>
          </HStack>
        </Box>
        <Box minW="80%">
          <VStack space="5">
            <Center>
              <Text>Spread Logo</Text>
            </Center>
            <Center>
              <Text>Spread It</Text>
            </Center>
            <Center>
              <HStack space="5">
                <Text>Get The App</Text>
                <Text>Learn More</Text>
              </HStack>
            </Center>
            <Center>
              <Text>Join X people on spread and elevate the conversation!</Text>
            </Center>
            <Center>
              <Text onPress={onLoginPressed}>Login</Text>
            </Center>
            <Center>
              <Text
                onPress={() => {
                  props.navigation.push("Profile", {
                    userId: "BKYUtmPV0cayVS7Bf2RYYxPMLiM2",
                  });
                }}
              >
                Let me read first
              </Text>
            </Center>
            <Center>
              <Text>Not on Spread yet?</Text>
            </Center>
            <Center>
              <HStack space="5">
                <Text>Name</Text>
                <Text>Email</Text>
                <Text>Join the wait-list</Text>
              </HStack>
            </Center>
          </VStack>
        </Box>
        <Box bottom="0" w="100%">
          <HStack justifyContent="right" space="5">
            <Text>Privacy</Text>
            <Text>Top Spreaders</Text>
          </HStack>
        </Box>
      </Center>
    </View>
  );
}
