import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { getDatabase, ref, onValue } from "firebase/database";
import React, { useState, useEffect } from "react";
import {
  AuthContextData,
  AuthContextUtilities,
  AuthProvider,
  AuthStatus,
} from "./auth_context";

// Find these options in your Firebase console
initializeApp({
  apiKey: "AIzaSyC_37ZZQ2z7IErN3UvJ-_anQM6l50hSJe0",
  authDomain: "spread-test-5910d.firebaseapp.com",
  databaseURL: "https://spread-test-5910d-default-rtdb.firebaseio.com",
  projectId: "spread-test-5910d",
  storageBucket: "spread-test-5910d.appspot.com",
  messagingSenderId: "163624887679",
  appId: "1:163624887679:web:3f68f2af81c6ae646da64a",
});

export default function Auth({ children }: { children?: React.ReactNode }) {
  const [authState, setAuthState] = useState<AuthContextData>(
    new AuthContextData(AuthStatus.LOADING)
  );
  const auth = getAuth();

  useEffect(() => {
    return auth.onAuthStateChanged(async (user) => {
      if (user) {
        const token = await user.getIdToken();
        const idTokenResult = await user.getIdTokenResult();
        const hasuraClaim =
          idTokenResult.claims["https://hasura.io/jwt/claims"];

        if (hasuraClaim) {
          setAuthState(new AuthContextData(AuthStatus.LOGGED_IN, user, token));
        } else {
          setAuthState(new AuthContextData(AuthStatus.LOADING, user, token));
          // Check if refresh is required.
          const db = getDatabase();
          const metadataRef = ref(db, "metadata/" + user.uid + "/refreshTime");

          onValue(metadataRef, async (data) => {
            if (!data.exists()) return;
            // Force refresh to pick up the latest custom claims changes.
            const token = await user.getIdToken(true);
            const idTokenResult = await user.getIdTokenResult(true);
            const hasuraClaim =
              idTokenResult.claims["https://hasura.io/jwt/claims"];
            if (hasuraClaim) {
              setAuthState(
                new AuthContextData(AuthStatus.LOGGED_IN, user, token)
              );
            } else {
              console.debug("No claims yet");
            }
          });
        }
      } else {
        setAuthState(new AuthContextData(AuthStatus.LOGGED_OUT));
      }
    });
  }, []);

  const signInWithGoogle = async () => {
    try {
      setAuthState(new AuthContextData(AuthStatus.LOADING));
      return await signInWithPopup(auth, new GoogleAuthProvider());
    } catch (error) {
      console.log(error);
    }
  };

  const signOut = async () => {
    try {
      setAuthState(new AuthContextData(AuthStatus.LOADING));
      await auth.signOut();
      setAuthState(new AuthContextData(AuthStatus.LOGGED_OUT));
    } catch (error) {
      console.log(error);
    }
  };

  const authUtilities = new AuthContextUtilities(signInWithGoogle, signOut);

  return (
    <div>
      <AuthProvider value={[authState, authUtilities]}>{children}</AuthProvider>
    </div>
  );
}
