import {
  Container,
  Box,
  VStack,
  Text,
  Center,
  View,
  Spinner,
} from "native-base";
import React, { useContext } from "react";
import AuthContext, { AuthStatus } from "../auth/auth_context";
import { useQueryProfile } from "../db_hooks/use_profile";
import { RootStackRouteProps, RootStackScreenProps } from "../navigation/types";
import { ReadingItemList } from "./reading_item_list";

interface IProfileProps {
  navigation: RootStackScreenProps<"Profile">;
  route: RootStackRouteProps<"Profile">;
}

export default function Profile(props: IProfileProps) {
  const routeUserId = props.route.params.userId;
  const auth = useContext(AuthContext);
  const firebaseUser = auth[0].firebase_user;
  const authUserId = firebaseUser?.uid;

  const profileHook = useQueryProfile(routeUserId);

  const firebaseDisplayName = firebaseUser?.displayName;
  const authUserName =
    auth[0].status === AuthStatus.LOGGED_OUT
      ? "Guest"
      : auth[0].status === AuthStatus.LOADING
      ? "Loading..."
      : firebaseDisplayName;

  if (routeUserId === authUserId) {
    // Profile of the user currently logged in
  }

  const profileUser = profileHook.data?.users.at(0);
  const displayName: string = profileHook.loading
    ? "Loading..."
    : profileUser?.name === undefined
    ? ""
    : profileUser?.name!;

  return (
    <View>
      <Center py="5" px={["5", "10", "20", "20"]}>
        <Box minW="80%">
          <VStack space="5">
            <Text onPress={() => props.navigation.push("Home")}>Spread</Text>
            <Text
              onPress={() => {
                if (auth[1].signOut) {
                  auth[1].signOut();
                }
              }}
            >
              {displayName}
            </Text>
            {auth[0].status === AuthStatus.LOADING ? (
              <Spinner accessibilityLabel="Loading..." />
            ) : (
              <ReadingItemList
                user_id={routeUserId}
                user_uuid={profileUser?.id ?? ""}
              />
            )}
          </VStack>
        </Box>
      </Center>
    </View>
  );
}
