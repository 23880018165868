import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { func } from "prop-types";

export interface QueryPostsData {
  posts: PostData[];
}

const POSTS_QUERY = gql`
  query Posts($id: String!) {
    posts(
      order_by: { created_at: desc }
      where: {
        user: { _or: [{ firebase_uid: { _eq: $id } }, { id: { _eq: $id } }] }
      }
    ) {
      reading_item {
        id
        link
      }
      user {
        id
      }
      type
      created_at
      id
    }
  }
`;

export function useQueryPosts(id: string) {
  return useQuery<QueryPostsData>(POSTS_QUERY, { variables: { id: id } });
}

const POSTS_CREATE = gql`
  mutation CreatePost($id: String!, $link: String!) {
    insert_posts_one(
      object: { user_id: $id, reading_item: { data: { link: $link } } }
    ) {
      id
      type
      user {
        id
      }
      reading_item {
        author
        title
        link
        id
      }
    }
  }
`;

export function useCreatePost() {
  return useMutation(POSTS_CREATE);
}
