import {
  Box,
  VStack,
  Text,
  Divider,
  AspectRatio,
  Image,
  Spinner,
} from "native-base";
import DOMPurify from "dompurify";

import React, { useEffect, useState } from "react";
import { Linking, Pressable } from "react-native";

interface OgCardProps {
  item: ReadingItemData;
}

export default function OgCard(props: OgCardProps) {
  const [parsed, setParsed] = useState<null | Map<String, String>>(null);
  useEffect(() => {
    const url =
      "https://og-parser.spread-test.workers.dev/og-parser/" +
      encodeURIComponent(props.item.link);

    const fetchData = async () => {
      try {
        const response = await fetch(url);
        const json = await response.json();
        setParsed(new Map<String, String>(Object.entries(json)));
      } catch (error) {
        console.log("error", error);
      }
    };
    fetchData();
  }, []);

  if (parsed == null) {
    return (
      <Box borderRadius="md">
        <VStack space="4" divider={<Divider />}>
          <Spinner accessibilityLabel="Loading..." />
        </VStack>
      </Box>
    );
  }
  const url = parsed.get("url") ?? props.item.link;
  const img_url = parsed.get("image");
  const title = parsed.get("title");
  const description = parsed.get("description") ?? "";
  const sanitized_description = DOMPurify.sanitize(description);

  const onPress = () => {
    Linking.openURL(url);
  };

  return (
    <Pressable onPress={onPress}>
      <Box
        shadow="2"
        rounded="lg"
        w={{ base: "64", md: "80", lg: "md" }}
        _light={{ bg: "coolGray.50" }}
        _dark={{ bg: "gray.700" }}
      >
        <AspectRatio w="100%" ratio={{ base: 4 / 3, md: 16 / 9 }}>
          <Image source={img_url} alt="image base" />
        </AspectRatio>
        <VStack space="2" p="4">
          <Text color="gray.400">{title}</Text>
          <Text>
            <div
              dangerouslySetInnerHTML={{
                __html: sanitized_description,
              }}
            ></div>
          </Text>
        </VStack>
      </Box>
    </Pressable>
  );
}
