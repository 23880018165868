import {
  Column,
  Divider,
  Row,
  Text,
  Box,
  Center,
  Input,
  Button,
  Spinner,
} from "native-base";
import React from "react";
import {
  useCreatePost,
  useQueryPosts,
} from "../db_hooks/use_reading_item_list";
import OgCard from "./og_card";

interface IReadingItemListProps {
  user_id: string;
  user_uuid: string;
}

export function ReadingItemList(props: IReadingItemListProps) {
  const [link, setLink] = React.useState("");
  const userId = props.user_id;
  const { data, loading, error, refetch } = useQueryPosts(userId);
  const [createReadingItem] = useCreatePost();

  if (error) {
    console.error(error.toString());
  }
  if (loading === true || data === undefined) {
    return <Spinner accessibilityLabel="Loading..." />;
  }

  return (
    <Center w="100%">
      <Box w="100%">
        <Box bg="grey" w="100%">
          <Text>What are you reading?</Text>
          <Input
            variant="underlined"
            placeholder="Link"
            value={link}
            onChangeText={(text) => {
              setLink(text);
            }}
          />
          <Button
            variant="ghost"
            bg="grey"
            colorScheme="dark"
            onPress={async () => {
              console.log(`clicked ${link}`);
              await createReadingItem({
                variables: {
                  id: props.user_uuid,
                  link: link,
                },
              });
              refetch();
            }}
          >
            Spread it
          </Button>
        </Box>
        <Column mt="3" space={3} bg="grey" divider={<Divider />} w="100%">
          {data.posts.map((post) => {
            return <OgCard key={post.id} item={post.reading_item} />;
          })}
        </Column>
      </Box>
    </Center>
  );
}
