import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { Button, View, Text } from "native-base";
import React from "react";
import { RootStackParamList } from "../navigation/types";

interface INotFoundScreenProps {
  navigation: NativeStackNavigationProp<RootStackParamList, "NotFound">;
}

export default function NotFoundScreen(props: INotFoundScreenProps) {
  return (
    <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
      <Text>Oops! Not found</Text>
      <Button
        title="Back to safety"
        onPress={() => props.navigation.navigate("Home")}
      />
    </View>
  );
}
