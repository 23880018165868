import React from "react";
// TODO: Make generic
import { User, UserCredential } from "firebase/auth";

export enum AuthStatus {
  LOGGED_OUT,
  LOADING,
  LOGGED_IN,
}

export class AuthContextData {
  constructor(
    status: AuthStatus,
    firebase_user?: User | undefined,
    token?: string | undefined
  ) {
    this.status = status;
    this.firebase_user = firebase_user;
    this.token = token;
  }

  status: AuthStatus;
  firebase_user?: User;
  token?: string;
}

export class AuthContextUtilities {
  constructor(
    signInWithGoogle?: () => Promise<UserCredential | undefined>,
    signOut?: () => Promise<void>
  ) {
    this.signInWithGoogle = signInWithGoogle;
    this.signOut = signOut;
  }
  signInWithGoogle?: () => Promise<UserCredential | undefined>;
  signOut?: () => Promise<void>;
}

const AuthContext = React.createContext<
  [AuthContextData, AuthContextUtilities]
>([new AuthContextData(AuthStatus.LOGGED_OUT), new AuthContextUtilities()]);
export const AuthProvider = AuthContext.Provider;
export default AuthContext;
