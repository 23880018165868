// In App.js in a new project

import * as React from "react";
import { LinkingOptions, NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { HausraApolloProvider } from "../db_hooks/hasura";
import { NativeBaseProvider } from "native-base";
import Profile from "./profile";
import NotFoundScreen from "./not_found";
import Auth from "../auth/firebase";
import {} from "@react-navigation/native-stack";
import { RootStackParamList } from "../navigation/types";
import HomeScreen from "./home";

const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [],
  config: {
    initialRouteName: "Home",
    screens: {
      Home: "/",
      Profile: "profile/:userId",
      NotFound: "*",
    },
  },
};

const Stack = createNativeStackNavigator();

function Entrypoint() {
  return (
    <Auth>
      <HausraApolloProvider>
        <NativeBaseProvider>
          <NavigationContainer linking={linking}>
            <Stack.Navigator>
              <Stack.Screen name="Home" component={HomeScreen} />
              <Stack.Screen name="Profile" component={Profile} />
              <Stack.Screen name="NotFound" component={NotFoundScreen} />
            </Stack.Navigator>
          </NavigationContainer>
        </NativeBaseProvider>
      </HausraApolloProvider>
    </Auth>
  );
}

export default Entrypoint;
