import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { validate as isValidUUID } from "uuid";

export interface QueryProfileData {
  users: UserData[];
}

const PROFILE_BY_ID_QUERY = gql`
  query Profile($id: String!) {
    users(
      where: { _or: [{ firebase_uid: { _eq: $id } }, { id: { _eq: $id } }] }
      limit: 1
    ) {
      id
      name
      firebase_uid
      profile_photo_url
    }
  }
`;

export function useQueryProfile(id: string) {
  return useQuery<QueryProfileData>(PROFILE_BY_ID_QUERY, {
    variables: { id: id },
  });
}
