import * as React from "react";
import {
  ApolloClient,
  ApolloProvider,
  gql,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";
import { useContext } from "react";
import AuthContext, { AuthContextData, AuthStatus } from "../auth/auth_context";

const ADD_FIREBASE_USER_MUTATION = gql`
  mutation {
    add_firebase_user {
      result
    }
  }
`;

const PROFILE_UPDATE_BY_FIREBASE_UID_MUTATION = gql`
  mutation ProfileUpdateByFirebaseUidMutation(
    $id: String!
    $name: String!
    $profile_photo_url: String
  ) {
    update_users(
      _set: { name: $name, profile_photo_url: $profile_photo_url }
      where: { firebase_uid: { _eq: $id } }
    ) {
      affected_rows
    }
  }
`;

const USER_BY_FIREBASE_UID_QUERY = gql`
  query Profile($id: String!) {
    users(where: { firebase_uid: { _eq: $id } }) {
      email
      id
      name
      firebase_uid
      profile_photo_url
    }
  }
`;

async function ensureUserInfo(
  client: ApolloClient<NormalizedCacheObject>,
  authContextData: AuthContextData
) {
  // Ensure user is in db, silently fail if we get a constraint error (user already in db)
  try {
    await client.mutate({ mutation: ADD_FIREBASE_USER_MUTATION });
  } catch (e) {
    console.debug("Error ensuring firebase user - existing user");
    console.debug(e);
    return;
  }
  console.debug("No error ensuring firebase user - new user");
  try {
    const ret = await client.query({
      query: USER_BY_FIREBASE_UID_QUERY,
      variables: {
        id: authContextData.firebase_user?.uid,
      },
    });
    const user = ret.data?.users[0];
    if (user.name === authContextData.firebase_user?.displayName) {
      console.debug("No need to update profile");
      return;
    }
  } catch (e) {
    console.debug("Error querying user profile");
    console.debug(e);
    return;
  }
  try {
    await client.mutate({
      mutation: PROFILE_UPDATE_BY_FIREBASE_UID_MUTATION,
      variables: {
        id: authContextData.firebase_user?.uid,
        name: authContextData.firebase_user?.displayName,
        profile_photo_url: authContextData.firebase_user?.photoURL,
      },
    });
  } catch (e) {
    console.debug("Error updating user profile");
    console.debug(e);
    return;
  }
}

function createClient(authContextData?: AuthContextData) {
  console.debug(`Creating client with token ${authContextData?.token}`);
  const client = new ApolloClient({
    uri: "https://spread-test-hasura.fly.dev/v1/graphql",
    headers:
      authContextData?.status === AuthStatus.LOGGED_IN
        ? {
            Authorization: `Bearer ${authContextData.token}`,
          }
        : {},
    cache: new InMemoryCache(),
  });
  if (
    authContextData?.token !== undefined &&
    authContextData.firebase_user !== undefined
  ) {
    ensureUserInfo(client, authContextData);
  }
  return client;
}

export function HausraApolloProvider({
  children,
}: {
  children?: React.ReactNode;
}) {
  const auth = useContext(AuthContext);
  const client = createClient(auth[0]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
